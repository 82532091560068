@import url('https://fonts.googleapis.com/css2?family=Inknut+Antiqua&family=Poppins:wght@300&family=Space+Grotesk:wght@300&display=swap');


:root {
  --font-family: 'Poppins', sans-serif;
  --background-color: #f1f2eb;
  --complimentary-color: #b4acac;
  --complimentary-green-color: #737c77;
  --black-color: #0c2934;
}

.indented-checkbox {
  margin-left: 20px;
}



.mt-4 {
  position: static;
  padding: 0px;
  padding-left: 10px;
  padding-right: 10px;
}

.close-form {
  position: absolute;
  top: 10px;
  right: 50px;
  cursor: pointer;
  font-size: 30px;
  color: #333;
  font-weight: light;
  padding: 5px;
  border-radius: 50%;
  background-color: var(--background-color);
  z-index: 1000;
}

@keyframes slideDown {
  from {
    transform: translateY(-100%);
  }

  to {
    transform: translateY(0);
  }
}

@keyframes slideUp {
  from {
    transform: translateY(0);
  }

  to {
    transform: translateY(-100%);
  }
}

.slide-up-form {
  animation: slideUp 0.5s ease-out forwards;
}


.animated-form {
  animation: slideDown 0.2s ease-out forwards;
  box-shadow: 8px 8px 8px rgba(0, 0, 0, 0.2);
  background-color: var(--background-color);
}

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
}

.hidden-form {
  display: none;
}

.landing-page-container {
  display: flex;
  justify-content: center;
  align-items: center;
  /* height: 100vh; */
  /* min-height: 100vh; */
  padding: 0;
  text-align: left;
  background-color: var(--background-color);
  margin-top: -75px;
  margin-bottom: 50px;
  padding-bottom: 100px;
}

.sign-up-section {
  flex: 0 0 75%;
  background-color: var(--background-color);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.small-image {
  width: 400px;
  height: auto;
  margin-bottom: 10px;
}

.balanced-brief-text {
  font-family: var(--font-family);
  font-weight: bold;
  color: var(--black-color);
  margin-top: -70px;
}

.lead-top {
  padding-top: 20px;
  font-family: var(--font-family);
  font-size: 24px;
  font-weight: bold;
  color: var(--black-color);
}

.lead-below {
  margin-top: 0;
  font-family: var(--font-family);
  font-size: 24px;
  font-weight: bold;
  color: var(--black-color);
  text-decoration: underline;
}

.summary {
  padding-top: 10px;
  padding-bottom: 10px;
  padding-right: 50px;
  font-family: var(--font-family);
  font-weight: normal;
  font-size: 16px;
  color: #171717;
  text-align: left;
}

.btn-primary, .form-control {
  box-sizing: border-box; /* Include padding and border in the element's total width and height */
  height: 40px; /* Match this height with the button */
  padding: 0.375rem 0.75rem; /* Bootstrap default padding for form-controls, adjust if necessary */
  border: 2px solid var(--complimentary-green-color); /* Match border width and color */
}

.btn-primary {
  font-family: var(--font-family);
  background-color: var(--complimentary-green-color) !important;
  /* No need to set border-color again since it's already defined above */
  line-height: 1; /* Reset line height to default to match the input field */
  /* Other styles */
}

.form-control:focus {
  box-shadow: none !important;
  /* No need to set border-color again since it's already defined above */
}


.form-control::placeholder {
  color: #1f1e1e;
  font-family: var(--font-family);
  font-weight: 100;
}

.form-container {
  width: 80%;
  max-width: 960px;
}


.full-page-form {
  display: grid;
  grid-template-rows: auto 1fr;
  align-items: start;
  justify-content: center;
  padding-top: -50px;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

/* You may want to hide the scroll bar when the full page form is active */
/* body.full-page-form-active {
  overflow: hidden;
} */

.error-message {
  color: red;
  font-size: 0.8em;
  margin-top: 5px;
}

.logo {
  /* Remove negative margins as they might cause overlap */
  margin-bottom: 0px;
  /* Adjust as needed */
  margin-top: 0px;
  /* Adjust as needed */
  grid-row: 1;
  height: auto;
  width: 300px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}


/* .logo {
    width: 300px;
    height: auto;
    margin-bottom: -300px;
    margin-top: -100px;
    display: block;
    margin-left: auto;
    margin-right: auto;
  } */

.form-check-input news {
  font-family: var(--font-family) !important;
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  /* Allows items to wrap to the next line if the container is not wide enough */
  margin-right: -15px;
  margin-left: -15px;
  padding-right: 15px;
  padding-left: 15px;
}

.category-group {
  border-bottom: var(--complimentary-green-color);
  border-bottom: 1px solid var(--complimentary-green-color);
  padding-bottom: 20px;
  padding-top: 20px;
  margin-left: 10px;
}

.form-group {
  padding-right: 15px;
  padding-left: 30px;
  flex: 1;
  /* Ensures that the form group takes up equal space in the container */
  width: 100%;
}

.form-group label {
  padding-left: 2px;
}

.col-md-6 {
  flex: 0 0 100%;
  max-width: 100%;
}

/* Responsive behavior */
@media (max-width: 768px) {
  .col-md-6 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}


.tabs {
  display: flex;
  justify-content: space-around;
  padding: 10px;
  background-color: #f1f1f1;
}

.tabs button {
  padding: 10px;
  border: none;
  background-color: transparent;
  cursor: pointer;
  font-family: var(--font-family);
}

.tabs button.active {
  border-bottom: 2px solid var(--complimentary-color);
}


.checkbox-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
  gap: 300px;
  /* Adjust the gap size as needed */
  margin-bottom: 20px;
}

.form-check label {
  display: flex;
  align-items: center;
  /* Aligns the checkbox and label */
  font-family: var(--font-family);
  color: var(--black-color);
  white-space: nowrap;
}


/* Phone shit */

@media only screen and (max-width: 768px) {

  .landing-page-container,
  .sign-up-section,
  .form-container {
    flex-direction: column;
  }

  .small-image,
  .logo {
    width: 100%;
    /* full width */
    margin-bottom: 20px;
  }

  .mt-4,
  .form-container {
    padding-left: 10px;
    padding-right: 10px;
    position: relative;
    display: flex;

  }

}

#root {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: var(--background-color);
}

.formik-form {
  background-color: var(--background-color);
}

.accordion {
  border: 1px solid var(--complimentary-green-color);
  background-color: var(--background-color);
  margin-bottom: 10px;
}

/* Form Field Styles */
input[type="text"], input[type="number"], input[type="email"] {
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 10px;
  margin: 5px 0;
}

/* Submit Button Styles */
button[type="submit"] {
  background-color: #007bff;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

button[type="submit"]:hover {
  background-color: #0056b3;
}

.form-section {
  margin-top: 25px;
  border-top: 1px solid var(--complimentary-green-color);
}

.name-fields {
  display: flex;
  justify-content: center;
  align-items: flex-start; /* Align items to the start of the flex container */
  margin-top: 20px;
}

.first-name-field {
  background-color: #ffffff;
  flex: 1; /* Takes 1/3 of the space */
  max-width: calc(50% - 10px);
  margin-right: 20px; /* Spacing between fields */
}

.first-name-field:focus {
  background-color: #000 !important;
}


.last-name-field {
  background-color: #ffffff;
  flex: 3; /* Takes 2/3 of the space */
  max-width: calc(50% - 10px);
}

.date-email-fields {
  margin-top: 10px;
  display: flex;
  justify-content: center;
  align-items: flex-start; /* Align items to the start of the flex container */
}

.date-of-birth-field {
  background-color: #ffffff;
  flex: 1; /* Takes 1/3 of the space */
  max-width: calc(50% - 10px);
  margin-right: 20px; /* Spacing between fields */
}

.email-text-box-field {
  background-color: #ffffff;
  flex: 3; /* Takes 2/3 of the space */
  max-width: calc(50% - 10px);
}

.submit-button {
  margin-top: 10px;
  display: flex;
  justify-content: center;
  align-items: flex-start; /* Align items to the start of the flex container */
  font-family: var(--font-family);
  border-color: var(--complimentary-green-color) !important;
  color: var(--complimentary-color) !important;
}

.submit-button-field {
  background-color: #000 !important;
}

.submitting {
  /* Style for when the form is submitting */
  background-color: #ccc;
  cursor: not-allowed;
}

.success {
  /* Style for when the submission is successful */
  background-color: #28a745;
  cursor: default;
}

.error-message {
  color: red;
}

.success-message {
  color: green;
}
